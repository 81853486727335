// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    
    z-index: 11;
    background: ${props.theme.colors.brand.bc1};

    padding: ${props.theme.space.small} 3.6rem;

    ${breakup.medium`
        padding: ${props.theme.space.medium};
    `}

    ${breakup.large`
        padding: ${props.theme.space.large};
    `}
`);


export const LoadMorePos = styled.div(props => css`
    margin-top: ${props.theme.space.small};

    ${breakup.medium`
        margin-top: ${props.theme.space.medium};
    `}

    ${breakup.large`
        margin-top: ${props.theme.space.large};
    `}
`);