// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section } from '@tackl';
import { Link } from 'gatsby';
import { pStyles } from '@tackl-type';

// Exports
// ------------
const loadRotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export const Jacket = styled.button(props => css`
    ${pStyles}    
    position: relative;
    display: inline-flex;
    gap: 1.2rem;
    align-items: center;
    justify-content: flex-start;

    color: ${props.theme.colors.brand.bc4};
    padding-bottom: 1rem;
    width: max-content;
    transition: all 0.75s ${props.theme.easing.bezzy};
    background: transparent;

    &:hover {
        ${breakup.large`
            color: ${props.theme.colors.brand.bc5};
            cursor: pointer;

            &:after {
                width: calc(100% - 6rem);
                transform: translateX(6rem);
            }

            svg {
                stroke: ${props.theme.colors.brand.bc4};
                animation: ${loadRotate} 1s linear both infinite;
            }
        `}
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0; left: 0;
        height: 0.2rem;

        background: ${props.theme.colors.brand.bc4};
        width: 3.6rem;
        transition: all 0.75s ${props.theme.easing.bezzy};

        ${breakup.large`
            width: 4.8rem;
            height: 0.3rem;
        `}
    }

    svg {
        width: 3.6rem;
        height: 3.6rem;
        fill: none;
        stroke: ${props.theme.colors.brand.bc5};
        stroke-width: 0.3rem;
        transition: all 0.75s ${props.theme.easing.bezzy};

        ${breakup.large`
            width: 4.8rem;
            height: 4.8rem;
        `}
    }


    span {
        color: inherit;
    }
`);
