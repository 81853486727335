// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Em, P } from '@tackl';
import { emStyles } from '@tackl-type';
import { Link } from 'gatsby';

// Exports
// ------------
export const ExtraSpace = styled.div(props => css`
    display: none;
    height: calc(30rem - ${props.theme.space.large});
    width: 100%;

    ${breakup.large` display: block; `}
`)


export const Content = styled.div(props => css`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    width: 100%;
    position: absolute;
    bottom: 0; left: 0; right: 0;
    padding: 2.4rem;
    z-index: 3;

    ${breakup.smedium`
        padding: 3.6rem;
    `}

    ${breakup.large`
        padding: 6rem;
    `}
`);

export const Background = styled.picture(props => css`
    position: absolute;
    z-index: 1;
    top: 0; left: 0; right: 0; bottom: 0;
    pointer-events: none;

    transform: scale(1.2);

    &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        background: linear-gradient(180deg,
            ${props.theme.colors.global.black}00 0%,
            ${props.theme.colors.global.black} 100%
            );
        opacity: 0.6;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;

        img {
            transition: all .75s ${props.theme.easing.bezzy};
        }
    }
`);


export const Date = styled.div(props => css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    time, span {
        ${emStyles}

        display: inline-flex;
        align-items: center;

        padding: 0.5rem 1.2rem 0.2rem;
        color: ${props.theme.colors.brand.bc5};
    }

    time {
        background: ${props.theme.colors.global.black20};
        border-radius: ${props.isFirst ? `1.8rem 0 0 1.8rem` : `1.8rem`};
        backdrop-filter: blur(2.4rem);
    }

    span {
        border-radius: 0 1.8rem 1.8rem 0;
        background: linear-gradient(to right,
            ${props.theme.colors.brand.bc2} 0%,
            ${props.theme.colors.brand.bc3} 100%);
    }
`);

export const Title = styled(P)(props => css`
    color: ${props.theme.colors.brand.bc5};
`);

export const AuthorRead = styled.div(props => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;

    ${breakup.large` justify-content: flex-start; `}
`);

export const Author = styled.div(props => css`
    display: flex;
    align-items: center;
    gap: 1.2rem;
`);

export const AuthorPic = styled.picture(props => css`
    display: block;
    border-radius: 3.6rem;
    width: 3.6rem;
    height: 3.6rem;
    overflow: hidden;

    .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
`);

export const AuthorName = styled(Em)(props => css`

`);

export const ReadTime = styled(Em)(props => css`
    opacity: 0.6;
`);


export const Jacket = styled(Link)(props => css`
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    
    height: 60vh;
    min-height: 48rem;
    max-height: 60rem;

    ${breakup.large`
        height: calc(100vh / 4 * 3);
        max-height: 68rem;
        min-height: 60rem;
        transform: scale(1) translateY(0%);
        transform-origin: top center;
    `}

    ${breakup.large`
        &:hover {
            ${Background} {
                img {
                    transform: scale(1.1) rotate(-6deg);
                }
            }
        }
    `}
`);
