// Imports
// ------------
import React, { useState, useEffect } from 'react';
import NewsCard from '../NewsCard';
import LoadMore from './LoadMore';
import Masonry from "react-responsive-masonry";
import { theme } from '@theme';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { AnimatePresence } from 'framer-motion';

// Styles
// ------------
import { Jacket, LoadMorePos } from './styles';

// Component
// ------------
const NewsListing = ({ allNews }) => {
    // NOTE • Breakpoints
    const bp = useBreakpoint();

    // NOTE • States
    const [visible, setVisible] = useState(6);
    const [newNews, setNewNews] = useState([]);

    // NOTE • Load more
    const handleLoadmore = () => {
        setVisible(visible + 6);
    };

    // NOTE • Onload: Update state with posts
    useEffect(() => {
        setNewNews(allNews);
    }, [allNews]);

    return (
        <Jacket pad id="intro">
            <AnimatePresence>
                {bp.large ? (
                    <Masonry columnsCount={2} gutter={theme.space.large} key="masonry-grid">
                        {newNews.slice(0, visible).map(({ slug, id, title, writtenBy, bg, newsBuilder, posted }, i) => (
                            <NewsCard
                                key={i}
                                itemNumber={i + 1}
                                slug={slug}
                                title={title}
                                writtenBy={writtenBy}
                                bg={bg}
                                readTime={newsBuilder}
                                datePosted={posted.on}
                            />
                        ))}
                    </Masonry>
                ) : (
                    <>
                        {newNews.slice(0, visible).map(({ slug, id, title, writtenBy, bg, newsBuilder, posted }, i) => (
                            <NewsCard
                                key={i}
                                itemNumber={i + 1}
                                slug={slug}
                                title={title}
                                writtenBy={writtenBy}
                                bg={bg}
                                readTime={newsBuilder}
                                datePosted={posted.on}
                            />
                        ))}
                    </>
                )}

                {visible < newNews.length && (
                    <LoadMorePos>
                        <LoadMore onClick={handleLoadmore} />
                    </LoadMorePos>
                )}
            </AnimatePresence>
        </Jacket>
    );
}

export default NewsListing;