// Imports
// ------------
import React from 'react';
import Icon from '@icons';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const LoadMore = ({ onClick }) => (
    <Jacket type="button" className="button" onClick={onClick}>
        <Icon type="load" />
        <span data-text="Load More">Load More</span>
    </Jacket>
);

export default LoadMore;