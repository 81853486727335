// Imports
// ------------
import React from 'react';
import Hero from '@parts/Hero';
import NewsListing from '@parts/News/NewsListing';
import CallToAction from '@parts/CallToAction';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';
import { graphql } from 'gatsby';



// Component
// ------------
const NewsPage = ({ data }) => {
	const dato = data.page;

	return (
		<>
			<Seo
				title={data.seo.seoMeta.title}
				desc={data.seo.seoMeta.desc}
			/>

			<Hero
				title={dato.pageTitle}
				excerpt={dato.pageExcerpt}
			/>

			<NewsListing
				allNews={data.news.nodes}
			/>

			<CallToAction
				title={data.cta.title}
			/>

			<Footer />
		</>
	);
}

export default NewsPage;

// GraphQL
// ------------
export const query = graphql`
	query NewsPageQuery {
		# NOTE • SEO
		seo: datoCmsNewsPage {
			seoMeta {
				title
				desc: description
			}
		}
		# NOTE • Call To Action
		cta: datoCmsCallToAction {
			title
		}

        # NOTE • News Listing
        page: datoCmsNewsPage {
            pageTitle: heroTitle
            pageExcerpt: heroSubtext
        }

		# NOTE • News
		news: allDatoCmsNewsPost {
			nodes {
				id
				slug
				title
                posted: meta {
                    on: firstPublishedAt
                }
				writtenBy {
					id
					name
					pic: authorPicture {
						alt
						data: gatsbyImageData(
							width: 72
							height: 72
							placeholder: BLURRED
							layout: FIXED
						)
					}
				}

				bg: featuredMedia {
					alt
					data: gatsbyImageData(
						width: 1440
						height: 900
						placeholder: BLURRED
						layout: FULL_WIDTH
					)
				}

                newsBuilder {
                    __typename
                }
			}
		}
	}
`
