// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

// Styles
// ------------
import { Jacket, ExtraSpace, Content, Background, Date, Title, AuthorRead, Author, AuthorPic, AuthorName, ReadTime } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const NewsCard = ({ slug, title, writtenBy, bg, itemNumber, readTime, datePosted }) => {
    // NOTE • Destructure
    const { pic, name } = writtenBy;

    // NOTE ª Post Date
    const posted = dayjs(datePosted).format('DD MMM YYYY');

    // NOTE • Refs
    const bgImage = useRef();
    const fullEl = useRef();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            ScrollTrigger.matchMedia({
                '(min-width: 1024px)': () => {
                    gsap.to(fullEl.current, {
                        scale: 1.2,
                        y: `-50%`,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: fullEl.current,
                            start: '0% 0%',
                            end: '100% 0%',
                            scrub: 0.5,
                            markers: false,
                        },
                    });

                    gsap.to(bgImage.current, {
                        scale: 1,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: fullEl.current,
                            start: '0% 100%',
                            end: '100% 0%',
                            scrub: 0.5,
                            markers: false,
                        },
                    });
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            {itemNumber === 2 && (
                <ExtraSpace />
            )}


            <motion.div
                style={{ width: '100%' }}
                initial={{
                    opacity: 0,
                    y: '50%'
                }}
                animate={{
                    opacity: 1,
                    y: '0%'
                }}
            >
                <Jacket to={`/news/${slug}`} ref={fullEl}>
                    <Content>
                        <Date isFirst={itemNumber === 1}>
                            <time>{posted}</time>
                            {itemNumber === 1 && (<span>New</span>)}
                        </Date>

                        <Title>{title}</Title>
                        <AuthorRead>
                            <Author>
                                <AuthorPic>
                                    <GatsbyImage image={pic.data} alt={pic.alt ? pic.alt : `Sirio Strategies • Author: ${name}`} />
                                </AuthorPic>

                                <AuthorName>By {name}</AuthorName>
                            </Author>

                            <ReadTime>{readTime?.length * 2} mins read</ReadTime>
                        </AuthorRead>
                    </Content>

                    <Background ref={bgImage}>
                        <GatsbyImage image={bg.data} alt={bg.alt ? bg.alt : "Sirio Strategies • News Image"} />
                    </Background>
                </Jacket>
            </motion.div>
        </>
    );
}

export default NewsCard;